import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import BackgroundImage from 'gatsby-background-image'
import Player from '../components/player'
import '../components/css/bg.css'

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "background.jpg" }) {
        childImageSharp {
          fluid(srcSetBreakpoints: [200, 500, 900, 1200, 2000], maxWidth: 2000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      },
    }
  `)
  var randomProperty = function (obj) {

    let keys = Object.keys(obj);
    return obj[keys[keys.length * Math.random() << 0]];

  };
  const background = randomProperty(data)
  return(
    <BackgroundImage className="bg" loading="eager" fadeIn={false} fluid={background.childImageSharp.fluid} style={{ height: '100vh', minHeight:'200px'  }}>
      <Layout >
        <SEO title="Слухати Радіо Миргород" />
        <Player />
      </Layout>
    </BackgroundImage>
)}

export default IndexPage
