import React from "react"
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon'
import Slider from '@material-ui/core/Slider';
import VolumeDown from '@material-ui/icons/VolumeDown';
import VolumeUp from '@material-ui/icons/VolumeUp';
import Grid from '@material-ui/core/Grid'


const Player = () => {
  const [play, setPlay] = React.useState(true);
  const [sizeBool, setSizeBool] = React.useState(true);
  const [value, setValue] = React.useState(100);
  const player = React.useRef()

  React.useEffect(() => {
    handleResize()
    function handleResize() {
      setSizeBool(window.innerHeight > window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue && player.current) {
      player.current.volume = newValue / 100
    }
    if (newValue === 0 && player.current) {
      player.current.volume = 0
    }
  };


  return (
    <div>

      {play ?
        <IconButton aria-label="play"
        style={{
          height: sizeBool ? 'clamp(16px, 27vh, 300px)' : 'clamp(16px, 27vw, 300px)',
          width: sizeBool ? 'clamp(16px, 27vh, 300px)' : 'clamp(16px, 27vw, 300px)',
        }}
        onClick={e => {
          player.current.play()
          setPlay(false)
        }}>
          <SvgIcon
            style={{
              fontSize: sizeBool ? 'clamp(16px, 25vh, 250px)' : 'clamp(16px, 25vw, 250px)', color: 'white', opacity: '0.7' }}
          >
            <path d="m 12,2.4375 c -5.2832447,0 -9.5625,4.2792553 -9.5625,9.5625 0,5.283245 4.2792553,9.5625 9.5625,9.5625 5.283245,0 9.5625,-4.279255 9.5625,-9.5625 0,-5.2832447 -4.279255,-9.5625 -9.5625,-9.5625 z m 0,1.125 c 4.647474,0 8.4375,3.7900252 8.4375,8.4375 0,4.647474 -3.790026,8.4375 -8.4375,8.4375 C 7.3525257,20.4375 3.5625,16.647474 3.5625,12 3.5625,7.3525252 7.3525258,3.5625 12,3.5625 Z M 10.4375,8.375 v 7.25 L 15.271484,12 Z" />
          </SvgIcon>
        </IconButton>
        :
        <IconButton aria-label="play"
          style={{
            height: sizeBool ? 'clamp(16px, 27vh, 300px)' : 'clamp(16px, 27vw, 300px)',
            width: sizeBool ? 'clamp(16px, 27vh, 300px)' : 'clamp(16px, 27vw, 300px)',
          }}
            onClick={e => {
          player.current.pause()
          setPlay(true)
        }}>
          <SvgIcon
            style={{
              fontSize: sizeBool ? 'clamp(16px, 25vh, 250px)' : 'clamp(16px, 25vw, 250px)', color: 'white', opacity: '0.7'
            }}
          >
            <path d="m 12,2.4414062 c -5.2809627,0 -9.5585938,4.2776311 -9.5585938,9.5585938 0,5.280963 4.2776311,9.558594 9.5585938,9.558594 5.280963,0 9.558594,-4.277631 9.558594,-9.558594 0,-5.2809627 -4.277631,-9.5585938 -9.558594,-9.5585938 z m 0,1.1171876 c 4.649763,0 8.441406,3.791643 8.441406,8.4414062 0,4.649763 -3.791643,8.441406 -8.441406,8.441406 C 7.3502368,20.441406 3.5585937,16.649763 3.5585938,12 3.5585938,7.3502368 7.3502368,3.5585937 12,3.5585938 Z M 9.4414062,8.4414062 V 15.558594 H 10.558594 V 8.4414062 Z m 3.9999998,0 v 7.1171878 h 1.117188 V 8.4414062 Z" />
          </SvgIcon>
        </IconButton>
      }
      {play ? null :
        <Grid
          direction="row"
          justify="center"
          alignItems="center"
          container spacing={2}
          style = {
            {
              height: '0',
              padding: '0',
              marginTop: '-20px',
              marginLeft: '0px',
              position: 'absolute',
              width: sizeBool ? 'clamp(16px, 27vh, 300px)' : 'clamp(16px, 27vw, 300px)',
            }
          }
        >
          <Grid item>
            <VolumeDown style={{ color: 'white', opacity: '0.7' }} />
          </Grid>
          <Grid item xs>
            <Slider style={{ color: 'white', opacity: '0.7' }} value={value} onChange={handleChange} aria-labelledby="continuous-slider" />
          </Grid>
          <Grid item>
            <VolumeUp style={{ color: 'white', opacity: '0.7' }} />
          </Grid>
        </Grid>
      }
      <audio ref={player} preload="none">
        <source src='https://myrhorodfm.com.ua:8443/live' type="audio/mpeg" />
        <source src ='http://176.126.163.102:16020/live' type="audio/mpeg" />
        <source src='http://212.26.132.129:8000/armiafm' type="audio/mpeg" />
        {/* <track kind="captions" srcLang="uk" label="ukrainian_captions"></track> */}
      </audio>
    </div>
  )
}

export default Player;
